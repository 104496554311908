.modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
  }

.modal {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;
    z-index: 10;
}

.modal .close {
    float: right;
    margin: 5px;
    padding: 7px;
}

.modal .content {
    padding: 20px;
    text-align: center;
}